.btn {
  background-color: #333;
  color: #ffffff;
  display: block;
  padding: 10px 15px;
  min-width: 126px;
  text-align: center;
  &--cart {
    background-color: #c0272d;
  }
  .fa {
    margin-right: 5px;
  }

  &--more {
    @include fontsize(14);
    font-weight: 600;
    background-color: #fff;
    color: #333;
    padding: 3px 30px 3px 15px;
    background-image: url(../../src/images/common/icn_arrowright.png);
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 10px;
    @include mq-down {
      background-position: 90% 50%;
      background-size: 5px;
      span {
        display: none;
      }
    }
  }
  &--secondary {
    background-color: #222;
    background-image: url(../../src/images/common/icn_arrowright_wh.png);
    color: #ffffff;
  }
}
