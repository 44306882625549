.bannerBlock {
  margin-top: 30px;
}
.bannerBlock__row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  @include mq-down {
    margin-right: -5px;
    margin-left: -5px;
  }
}
.bannerBlock__row > div[class*="col-"] {
  padding-right: 10px;
  padding-left: 10px;
  @include mq-down {
    padding-right: 5px;
    padding-left: 5px;
  }
}
.bannerBlock__item {
  padding-bottom: 20px;
  @include mq-down {
    padding-bottom: 15px;
  }
}
