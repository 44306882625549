//main:../style.scss
@charset "UTF-8";
// 新メディアクエリ用設定
$breakpoints: (
  "sm": "(min-width: 576px) and (max-width: 767px)",
  "md": "(min-width: 768px) and (max-width: 991px)",
  "lg": "(min-width: 992px) and (max-width: 1199px)"
) !default;

// min-width
$breakpoint-up: (
  "sm": "screen and (min-width: 576px)",
  "md": "screen and (min-width: 768px)",
  "lg": "screen and (min-width: 992px)",
  "xl": "screen and (min-width: 1200px)"
) !default;

// max-width
$breakpoint-down: (
  "sm": "screen and (max-width: 575px)",
  "md": "screen and (max-width: 767px)",
  "lg": "screen and (max-width: 991px)",
  "xl": "screen and (max-width: 1199px)"
) !default;

// @mixinの定義
@mixin mq($breakpoint: sm) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin mq-up($breakpoint: sm) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-down($breakpoint: sm) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

$prefix: "-webkit-", "-moz-", "-o-", "-ms-", "";
// 不透明度設定
// 例) @include opacity(0.5);
// @mixin opacity($opacity) {
//     opacity: $opacity;
//     $opacityIE: $opacity * 100;
//     filter: alpha(opacity=$opacityIE);
// }
@mixin opacity($opacity) {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  @include mq-up() {
    &:hover {
      opacity: $opacity;
      $opacityIE: $opacity * 100;
      filter: alpha(opacity=$opacityIE);
    }
  }
}

// Transition
@mixin transition($property) {
  -webkit-transition: $property 0.2s linear;
  transition: $property 0.2s linear;
}

// パーセント表示用（サイズ変更可能）
// フォントサイズ変更用JSなど使用する際に有効
//@mixin fontsize($fontSize, $baseFontSize:13){
//    font-size: percentage($fontSize / $baseFontSize);
//}
// rem表示用（サイズ変更不可）
// フォントサイズ固定の際に有効
@mixin fontsize($size, $base: 16) {
  // font-size: $size + px;
  font-size: ($size / $base) + rem;
}

// clearfix
// 例) @include clearfix;
@mixin clearfix() {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

// FontAwesome
// 例) @include font-awesome;
@mixin font-awesome() {
  font-family: "FontAwesome";
}

// sans-serif
// 例) @include font-sansserif;
@mixin font-sansserif() {
  font-family: Arial, sans-serif;
}

@mixin font-serif()  {
  font-family: 'Noto Serif JP', serif;
}
