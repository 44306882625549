//main:../style.scss
/* ===================================

    footer

=================================== */
.footer {
  margin-top: 130px;
  position: relative;
  @include mq-down {
    margin-top: 65px;
  }
}

.footerGuide {
  background: url(../../src/images/common/bg_header.png);
  &__item {
    margin-bottom: 45px;
    p {
      margin-bottom: 1em;
    }
  }
  &__title {
    background-color: #333333;
    color: #ffffff;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include fontsize(30);
    margin-bottom: 60px;
    span {
      display: block;
      @include fontsize(16);
    }
  }
  &__card {
    background-color: #ffffff;
    height: 100%;
    padding: 20px 20px 56px;
    position: relative;
    &Title {
      @include fontsize(18);
      font-weight: 500;
      text-align: center;
      margin-bottom: 1em;
      padding-top: 30px;
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: auto 25px;
      &--payment {
        background-image: url(../../src/images/common/icn_payment.png);
      }
      &--shipping {
        background-image: url(../../src/images/common/icn_shipping.png);
      }
      &--deliver {
        background-image: url(../../src/images/common/icn_deliver.png);
      }
      &--return {
        background-image: url(../../src/images/common/icn_return.png);
      }
      &--privacy {
        background-image: url(../../src/images/common/icn_privacy.png);
      }
      &--contact {
        background-image: url(../../src/images/common/icn_home.png);
      }
    }
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 36px;
    background-color: #E5E5E5;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    &--contact {
      background-color: #333;
      color: #ffffff;
      .fa {
        margin-right: 5px;
      }
    }
    &::before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(-45deg);
      position: absolute;
      top: 11px;
      right: 15px;
      z-index: 1;
    }
    &::after {
      content: '';
      display: block;
      width: 36px;
      height: 36px;
      background-color: #333;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.footerNav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px;
  &__item {
    line-height: 1;
    padding: 0 5px;
    &:not(:first-child) {
      border-left: 1px solid #333;
      @include mq-down {
        border-left: 0;
      }
    }

    @include mq-down {
      padding: 5px;
    }
  }
}

.paymentBlock {
  margin-right: 0;
  margin-left: 0;
  &__item {
    padding: 5px;
    text-align: center;
    span {
      display: block;
      border: 1px solid #949494;
      padding: 10px;
    }
  }
}

.table {
  width: 100%;
  border: 1px solid #DCDCDC;
  border-bottom: 0;
  tr {
    border-bottom: 1px solid #DCDCDC;
  }
  th, td {
    padding: 5px;
  }
  th {
    text-align: left;
    background-color: #F1F1F1;
  }
  td {
    text-align: right;
  }
}

.copyright {
  display: block;
  background-color: #333333;
  text-align: center;
  color: #ffffff;
  padding: 10px;
}

.pagetop {
  display: inline-block;
  width: 60px;
  height: 60px;
  background: url(../../src/images/common/backtotop@2x.png) 50% 50% no-repeat;
  background-size: 60px;
  position: fixed;
  bottom: 5%;
  right: 5%;
  &.is-hidden {
    opacity: 0;
  }
  &.is-fixed {
    position: absolute;
    top: -30px;
    bottom: auto;
    right: 5%;
  }
}