//main:../style.scss
// 変数設定 ==================================================
$contents_width: 1130px;
$text_color: #221815;

$hover_color: #cc5d31;
$sub_color: #00a7f3;
$header_color: #fff;
$footer_color: #065456;

$color_brown: #5d493c;
$color_orange: #e55934;
