//main:../style.scss
/* ===================================

    sidebar

=================================== */
.sidebar {
  @include mq-up {
    // max-width: 27.2727%;
    // flex: 1 0 27.2727%;
    // max-width: 300px;
    // padding-right: 2.72727%;
    margin-right: 30px;
    width: 270px;
  }
  @include mq-down {
    max-width: 100%;
    margin-top: 30px;
    order: 2;
    display: flex;
    flex-wrap: wrap;
  }
}

.sideBnr {
  display: block;
  margin-bottom: 15px;
  // @include mq-up {
  //   width: 270px;
  // }
  @include mq-down {
    max-width: 50%;
    flex: 1 0 50%;
    padding-right: 5px;
    padding-left: 5px;
  }
}

.sideCategoryNav {
  width: 100%;
  margin-bottom: 15px;
  @include mq-down {
    order: 1;
  }

  &__title {
    @include fontsize(20);
    font-weight: 700;
    background-color: #333;
    color: #ffffff;
    padding: 10px;
    text-align: center;
  }
  &__item {
    border-bottom: 1px solid #DEDEDE;
    background-position: 10px 50%;
    background-repeat: no-repeat;
    background-size: 50px;
    &--cat1 {
      background-image: url(../../src/images/category/icn-cat01.png);
    }
    &--cat2 {
      background-image: url(../../src/images/category/icn-cat02.png);
    }
    &--cat3 {
      background-image: url(../../src/images/category/icn-cat03.png);
    }
    &--cat4 {
      background-image: url(../../src/images/category/icn-cat04.png);
    }
    &--cat5 {
      background-image: url(../../src/images/category/icn-cat05.png);
    }
    &--cat6 {
      background-image: url(../../src/images/category/icn-cat06.png);
    }
    &--cat7 {
      background-image: url(../../src/images/category/icn-cat07.png);
    }
    &--cat8 {
      background-image: url(../../src/images/category/icn-cat08.png);
    }
    &--cat9 {
      background-image: url(../../src/images/category/icn-cat09.png);
    }
    &--cat10 {
      background-image: url(../../src/images/category/icn-cat10.png);
    }
    &--cat11 {
      background-image: url(../../src/images/category/icn-cat11.png);
    }
    &--cat12 {
      background-image: url(../../src/images/category/icn-cat12.png);
    }
    &--cat13 {
      background-image: url(../../src/images/category/icn-cat13.png);
    }
    &--cat14 {
      background-image: url(../../src/images/category/icn-cat14.png);
    }
  }
  &__link {
    display: flex;
    align-items: center;
    padding: 15px 15px 15px 70px;
    @include fontsize(13);
    font-weight: 700;
    letter-spacing: -0.1em;
    min-height: 66px;
  }
}