.ec-borderedDefs {
  .cl-faqBlock {
    display: flex;
    flex-direction: column;
    dt, dd {
      @include mq-up(md) {
        width: 100%;
        padding: 10px;
      }
    }
    &__question {
      display: flex;
      @include fontsize(20);
      line-height: 1;
      padding-bottom: 10px;
    }
    &__answer {
      display: flex;
      font-weight: normal;
    }
    &__icon {
      @include fontsize(20);
      line-height: 1;
      width: 40px;
      text-align: right;
      padding-right: 10px;
      vertical-align: top;
      &--question {
        color: #c0272d;
      }
      &--answer {
        color: #1B1464;
      }
    }
    &__answerText {
      display: inline-block;
      width: calc(100% - 50px);
      p {
        line-height: 1.6;
      }
    }
  }
}