@charset "utf-8";
/* ===================================

    index page

=================================== */
.contents {
  body:not(.front_page) & {
    display: block;
    padding-top: 35px;
    @include mq-down {
      padding-top: 90px;
    }
  }
}
.hero {
  margin-bottom: 60px;
  overflow: hidden;
  @include mq-down {
    margin-top: 90px;
    margin-bottom: 15px;
  }
  .slider-nav {
    background-color: #F1F1F1;
    .slick-track {
      display: flex;
      justify-content: center;
      width: 100% !important;
      transform: none !important;
    }
    .slick-slide {
      width: 94px !important;
      margin: 10px;
      &:not(.slick-current) {
        opacity: 0.5;
      }
    }
  }
  .slick-arrow {
    position: absolute;
    top: calc(50% - 28px);
    background-color: rgba(51,51,51,0.6);
    width: 28px;
    height: 56px;
    cursor: pointer;
    @include fontsize(20);
    color: #ffffff;
    @include mq-down {
      display: none !important;
    }

    .fa-angle-right {
      margin-left: -5px;
    }

    .fa-angle-left {
      margin-left: 5px;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 2;
      color: #ffffff;
    }

    &.slick-next {
      right: calc(200px - 28px);
      border-radius: 0 100% 100% 0 / 50%;
      // &::before {
      //   content: '\f105';
      //   font-family: "FontAwesome";
      //   font-weight: 700;
      // }
    }
    &.slick-prev {
      left: calc(200px - 28px);
      border-radius: 100% 0 0 100% / 50%;
      z-index: 1;
    }
  }
}
.sec-head {
  background-color: #222222;
  color: #ffffff;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: 40px;
  @include fontsize(24);

  @include mq-down {
    padding: 10px;
    height: 40px;
  }

  &--secondary {
    background-color: transparent;
    color: #333333;
    border-top: 1px solid #333;
  }
  .recommended & {
    background: url(../../src/images/common/back.jpg);
  }
  .sec-ranking & {
    margin-top: 80px;
    margin-bottom: 10px;
    @include mq-down {
      margin-top: 40px;
    }
  }
  .sec-category & {
    margin-top: 80px;
    margin-bottom: 20px;
    @include mq-down {
      margin-top: 40px;
    }
  }
}
.sec-title {
  white-space: nowrap;
  @include font-serif();
  font-weight: 700;
  display: flex;
  align-items: center;
  > span {
    @include fontsize(14);
    margin-left: 30px;
    @include mq-down {
      @include fontsize(10);
      margin-left: 15px;
    }
  }
}
.recommended {
  margin-bottom: 45px;
  .row.no-gutters {
    @include mq-down {
      overflow-x: auto;
      flex-wrap: nowrap;
    }
  }
}
.sec-ranking {
  .row.no-gutters {
    @include mq-down {
      overflow-x: auto;
      flex-wrap: nowrap;
    }
  }
}
.contentRight {
  width: 100%;
  @include mq-up {
    // max-width: 72.7272%;
    // flex: 1 0 72.7272%;
    // max-width: 800px;
    padding-right: 0;
    padding-left: 0;
    width: 800px;
  }
}
.bnr {
  margin-bottom: 50px;
}
.promItem {
  display: block;
  margin-bottom: 30px;
  text-align: center;
  &__caption {
    padding: 10px;
  }
  &__title {
    @include fontsize(18);
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 5px;
    @include mq-down {
      margin-top: 0;
    }
  }
  &__text {
    color: #666;
    text-align: left;
  }
}
.productItem {

  .recommended &, .sec-ranking & {
    @include mq-down {
      max-width: 33%;
      flex: 1 0 33%;
    }
  }

  &__link {
    display: flex;
    width: 100%;
    height: 0%;
    padding-bottom: 100%;
    position: relative;
    border: 1px solid #F1F1F1;
    border-bottom: 1px solid #222;
    img {
      max-width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
    }
  }
  &__catlink {
    display: flex;
    @include fontsize(12);
    flex-direction: column;
    justify-content: space-around;
    border: 1px solid #F1F1F1;
    padding: 10px;
    height: 100%;
    text-align: center;
    // min-height: 166px;
  }
  &__image {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      max-height: 100px;
    }
  }
  &__desc {
    background-color: #ffffff;
    color: #333333;
    padding: 15px;
    &--secondary {
      background-color: #ffffff;
      color: #333333;
    }
  }

  &--ranking {
    counter-increment: num;
    position: relative;
    &::before {
      content: counter(num);
      display: inline-block;
      @include fontsize(20);
      font-weight: 700;
      color: #fff;
      position: absolute;
      top: 0;
      left: 8px;
      z-index: 1;
    }
    &::after {
      content: '';
      display: inline-block;
      width: 50px;
      height: 50px;
      border-width: 25px;
      border-style: solid;
      border-color: #C6B198 transparent transparent #C6B198;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      text-indent: -1em;
      z-index: 0;
    }
  }

  &--category {
    max-width: 20%;
    flex: 1 0 20%;
  }
}
.entryList {
  padding: 10px;
  border: 1px solid #F1F1F1;
}
.entry {
  display: flex;
  padding: 15px 30px;
  @include mq-down {
    flex-direction: column;
    padding-right: 5px;
    padding-left: 5px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #F1F1F1;
  }
  &__link {
    display: block;
    font-weight: 700;
    // padding: 15px 30px;
    // @include mq-down {
    //   text-overflow: ellipsis;
    //   overflow: hidden;
    //   white-space: nowrap;
    //   padding-right: 15px;
    //   padding-left: 15px;
    // }
  }
  &__date {
    margin-right: 30px;
    @include mq-down {
      display: block;
    }
  }
  &__title {
    @include fontsize(16);
  }
  &__desc {
    color: #999999;
    font-weight: 400;
  }
}
.sec-category {
  @include mq-down {
    display: none;
  }
}
