//main:../style.scss
@charset "UTF-8";
/************************** reset設定 **************************/
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

body {
	line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

nav ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
	content: '';
	content: none;
}

a {
	margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

ins {
	background-color: #ff9;
	color: #000;
	text-decoration: none;
}

mark {
	background-color: #ff9;
	color: #000;
	font-style: italic;
	font-weight: 700;
}

del {
	text-decoration: line-through;
}

abbr[title],
dfn[title] {
	border-bottom: 1px dotted;
	cursor: help;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

input,
select {
	vertical-align: middle;
}

*::after,
*::before,
a,
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
button,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
input,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
textarea,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	-ms-overflow-style: scrollbar;
	font-size: 16px;
	// @include mq-down() {
	// 	font-size: 14px;
	// }
}

body {
	// font-family: "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
	font-family: "Noto Sans JP", "游ゴシック体", "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", Arial, sans-serif;
	position: relative;
	min-width: 320px;
	-webkit-text-size-adjust: 100%;
	font-weight: 400;
	line-height: 1.6;
	color: $text_color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
}

li {
	list-style-type: none;
}

a {
	text-decoration: none;
	color: $text_color;
	outline: 0;
}

img {
	border: 0;
	vertical-align: middle;

	&:not([width]) {
		max-width: 100%;
		height: auto;
	}
	@include mq-down() {
		max-width: 100%;
		height: auto;
	}
}

em,
i {
	font-style: normal;
}

button,
// input:not([type="checkbox"]):not([type="radio"]),
select,
textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	border-radius: 0;
	border: 0;
	outline: 0;
	margin: 0;
	padding: 0;
	background: #fff;
	vertical-align: middle;
	font-size: inherit;
	color: #333;
}

input::-webkit-input-placeholder {
	color: #bfbfbf;
}

input:-ms-input-placeholder {
	color: #bfbfbf;
}

input::-moz-placeholder {
	color: #bfbfbf;
}

option {
	outline: 0;
}

sup {
	position: relative;
	top: -.7em;
	font-size: 70%;
}

a[href^="tel:"].tel_disable {
	pointer-events: none;
	text-decoration: none;
}

img[src$=".svg"] {
	width: 100%;
}

@-ms-viewport {
  width: device-width;
}
