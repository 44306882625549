//main:../style.scss
/* ===================================

    header

=================================== */
.header {
  background: url(../../src/images/common/bg_header.png);
  padding-right: 15px;
  padding-left: 15px;
  @include mq-down {
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding-right: 5px;
    padding-left: 5px;
  }
  .headerIn {
    max-width: $contents_width;
    margin-right: auto;
    margin-left: auto;
    height: inherit;
  }
  .headerTop {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    @include mq-down {
      display: none;
    }
  }
  .headerBtm {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    height: inherit;
    @include mq-down {
      justify-content: flex-start;
    }
  }

  &__desc {
    @include fontsize(12);
  }
  &__logo {
    text-align: center;
    @include mq-down {
      width: 65%;
      display: flex;
      align-items: flex-start;
      text-align: left;
    }
    & &Img {
      @include mq-down {
        max-width: 135px;
      }
    }
    & &Text {
      font-family: 'Noto Serif JP', serif;
      @include mq-down {
        display: block;
        @include fontsize(10);
        margin-left: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
  &__nav {
    display: flex;
    &Item {
      padding-left: 20px;
      margin-left: 20px;
      background-position: 0 50%;
      background-repeat: no-repeat;
      background-size: 16px;

      &--member {
        background-image: url(../../src/images/common/icn_member.png);
      }
      &--login {
        background-image: url(../../src/images/common/icn_login.png);
      }
      &--guide {
        background-image: url(../../src/images/common/icn_doc.png);
      }
      &--faq {
        background-image: url(../../src/images/common/icn_faq.png);
      }
      &--company {
        background-image: url(../../src/images/common/icn_company.png);
      }

      a {
        @include fontsize(12);
      }
    }
  }
  &__tools {
    display: flex;
    @include mq-down {
      width: calc(35% - 40px);
    }
  }
  &__tel {
    @include mq-down {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      text-align: center;
      &::before {
        content: '\f095';
        display: block;
        font-family: "FontAwesome";
        @include fontsize(26);
        font-weight: 400;
        // line-height: 17px;
      }
      // &::after {
      //   content: 'TEL';
      // }
    }
    &Elm {
      // background-color: #fff;
      padding: 10px;
    }
    &Num {
      @include fontsize(30);
      font-weight: 700;
      line-height: 1;
      padding-left: 40px;
      background: url(../../src/images/common/icn-freedial.png) 0 70% no-repeat;
    }
    &Text {
      @include fontsize(15);
      font-weight: 700;
    }
  }
  &__btn {
    margin-left: 30px;
    @include mq-down {
      margin-left: 0;
      width: 50%;
    }
  }
  &__btnList {
    display: flex;
    justify-content: center;
  }
  &__btnItem {
    &:not(:first-child) {
      margin-left: 10px;
      @include mq-down {
        margin-left: 0;
      }
    }
    & .btn {
      font-weight: 700;
    }
    & .btn:not(.btn--cart) {
      @include mq-down {
        display: none;
      }
    }
    & .btn--cart {
      @include mq-down {
        @include fontsize(0);
        display: block;
        min-width: 1px;
        background-color: transparent;
        padding: 0;
        color: #222;
      }
    }
  }
  &__infoList {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    @include mq-down {
      display: none;
    }
  }
  &__infoItem {
    margin-left: 20px;
    padding-left: 22px;
    background-position: 0 50%;
    background-repeat: no-repeat;
    background-size: 18px;
    &--catalog {
      background-image: url(../../src/images/common/icn_doc.png);
    }
    &--mailmag {
      background-image: url(../../src/images/common/icn_mail.png);
    }
    &--quotes {
      background-image: url(../../src/images/common/icn_quotes.png);
    }
    a {
      @include fontsize(12);
      font-weight: 700;
    }
  }
  .fa-shopping-cart {
    @include fontsize(20);
    @include mq-down {
      display: block;
      color: #222;
      @include fontsize(26);
    }
  }
}
