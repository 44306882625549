@charset "UTF-8";
/************************** marginpadding設定 **************************/
.mt0 {
  margin-top: 0px !important;
}

.pt0 {
  padding-top: 0px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.pl0 {
  padding-left: 0px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.mt75 {
  margin-top: 75px !important;
}

.pt75 {
  padding-top: 75px !important;
}

.mb75 {
  margin-bottom: 75px !important;
}

.pb75 {
  padding-bottom: 75px !important;
}

.ml75 {
  margin-left: 75px !important;
}

.pl75 {
  padding-left: 75px !important;
}

.mr75 {
  margin-right: 75px !important;
}

.pr75 {
  padding-right: 75px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.pt100 {
  padding-top: 100px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mb120 {
  margin-bottom: 100px !important;
}

.pb100 {
  padding-bottom: 100px !important;
}

.ml100 {
  margin-left: 100px !important;
}

.pl100 {
  padding-left: 100px !important;
}

.mr100 {
  margin-right: 100px !important;
}

.pr100 {
  padding-right: 100px !important;
}

/************************** サイズ設定 **************************/
/* % size */
.w1 {
  width: 1%;
}

.w2 {
  width: 2%;
}

.w2-5 {
  width: 2.5%;
}

.w3 {
  width: 3%;
}

.w4 {
  width: 4%;
}

.w5 {
  width: 5%;
}

.w6 {
  width: 6%;
}

.w7 {
  width: 7%;
}

.w7-5 {
  width: 7.5%;
}

.w8 {
  width: 8%;
}

.w8 {
  width: 8%;
}

.w9 {
  width: 9%;
}

.w10 {
  width: 10%;
}

.w11 {
  width: 11%;
}

.w12 {
  width: 12%;
}

.w12-5 {
  width: 12.5%;
}

.w13 {
  width: 13%;
}

.w14 {
  width: 11%;
}

.w15 {
  width: 15%;
}

.w16 {
  width: 16%;
}

.w17 {
  width: 17%;
}

.w17-5 {
  width: 17.5%;
}

.w18 {
  width: 18%;
}

.w19 {
  width: 19%;
}

.w20 {
  width: 20%;
}

.w21 {
  width: 21%;
}

.w22 {
  width: 22%;
}

.w22-5 {
  width: 22.5%;
}

.w23 {
  width: 23%;
}

.w24 {
  width: 24%;
}

.w25 {
  width: 25%;
}

.w26 {
  width: 26%;
}

.w27 {
  width: 27%;
}

.w27-5 {
  width: 27.5%;
}

.w28 {
  width: 28%;
}

.w29 {
  width: 29%;
}

.w30 {
  width: 30%;
}

.w32-5 {
  width: 32.5%;
}

.w35 {
  width: 35%;
}

.w37-5 {
  width: 37.5%;
}

.w40 {
  width: 40%;
}

.w42-5 {
  width: 42.5%;
}

.w45 {
  width: 45%;
}

.w47-5 {
  width: 47.5%;
}

.w50 {
  width: 50%;
}

.w52-5 {
  width: 52.5%;
}

.w55 {
  width: 55%;
}

.w57-5 {
  width: 57.5%;
}

.w60 {
  width: 60%;
}

.w62-5 {
  width: 62.5%;
}

.w65 {
  width: 65%;
}

.w67-5 {
  width: 67.5%;
}

.w70 {
  width: 70%;
}

.w72-5 {
  width: 72.5%;
}

.w75 {
  width: 75%;
}

.w77-5 {
  width: 77.5%;
}

.w80 {
  width: 80%;
}

.w82-5 {
  width: 82.5%;
}

.w85 {
  width: 85%;
}

.w87-5 {
  width: 87.5%;
}

.w90 {
  width: 90%;
}

.w92-5 {
  width: 92.5%;
}

.w95 {
  width: 95%;
}

.w97-5 {
  width: 97.5%;
}

.w100 {
  width: 100%;
}

/* max% size */
.maxw2-5 {
  max-width: 2.5%;
}

.maxw5 {
  max-width: 5%;
}

.maxw7-5 {
  max-width: 7.5%;
}

.maxw10 {
  max-width: 10%;
}

.maxw12-5 {
  max-width: 12.5%;
}

.maxw15 {
  max-width: 15%;
}

.maxw17-5 {
  max-width: 17.5%;
}

.maxw20 {
  max-width: 20%;
}

.maxw22-5 {
  max-width: 22.5%;
}

.maxw25 {
  max-width: 25%;
}

.maxw27-5 {
  max-width: 27.5%;
}

.maxw30 {
  max-width: 30%;
}

.maxw32-5 {
  max-width: 32.5%;
}

.maxw35 {
  max-width: 35%;
}

.maxw37-5 {
  max-width: 37.5%;
}

.maxw40 {
  max-width: 40%;
}

.maxw42-5 {
  max-width: 42.5%;
}

.maxw45 {
  max-width: 45%;
}

.maxw47-5 {
  max-width: 47.5%;
}

.maxw50 {
  max-width: 50%;
}

.maxw52-5 {
  max-width: 52.5%;
}

.maxw55 {
  max-width: 55%;
}

.maxw57-5 {
  max-width: 57.5%;
}

.maxw60 {
  max-width: 60%;
}

.maxw62-5 {
  max-width: 62.5%;
}

.maxw65 {
  max-width: 65%;
}

.maxw67-5 {
  max-width: 67.5%;
}

.maxw70 {
  max-width: 70%;
}

.maxw72-5 {
  max-width: 72.5%;
}

.maxw75 {
  max-width: 75%;
}

.maxw77-5 {
  max-width: 77.5%;
}

.maxw80 {
  max-width: 80%;
}

.maxw82-5 {
  max-width: 82.5%;
}

.maxw85 {
  max-width: 85%;
}

.maxw87-5 {
  max-width: 87.5%;
}

.maxw90 {
  max-width: 90%;
}

.maxw92-5 {
  max-width: 92.5%;
}

.maxw95 {
  max-width: 95%;
}

.maxw97-5 {
  max-width: 97.5%;
}

.maxw100 {
  max-width: 100%;
}

/* EM size */
.w1em {
  width: 1em;
}

.w2em {
  width: 2em;
}

.w3em {
  width: 3em;
}

.w4em {
  width: 4em;
}

.w5em {
  width: 5em;
}

.w6em {
  width: 6em;
}

.w7em {
  width: 7em;
}

.w8em {
  width: 8em;
}

.w9em {
  width: 9em;
}

.w10em {
  width: 10em;
}

.w11em {
  width: 11em;
}

.w12em {
  width: 12em;
}

.w13em {
  width: 13em;
}

.w14em {
  width: 14em;
}

.w15em {
  width: 15em;
}

.w16em {
  width: 16em;
}

.w17em {
  width: 17em;
}

.w18em {
  width: 18em;
}

.w19em {
  width: 19em;
}

.w20em {
  width: 20em;
}

/* minEM size */
.minw1em {
  min-width: 1em;
}

.minw2em {
  min-width: 2em;
}

.minw3em {
  min-width: 3em;
}

.minw4em {
  min-width: 4em;
}

.minw5em {
  min-width: 5em;
}

.minw6em {
  min-width: 6em;
}

.minw7em {
  min-width: 7em;
}

.minw8em {
  min-width: 8em;
}

.minw9em {
  min-width: 9em;
}

.minw10em {
  min-width: 10em;
}

.minw11em {
  min-width: 11em;
}

.minw12em {
  min-width: 12em;
}

.minw13em {
  min-width: 13em;
}

.minw14em {
  min-width: 14em;
}

.minw15em {
  min-width: 15em;
}

.minw16em {
  min-width: 16em;
}

.minw17em {
  min-width: 17em;
}

.minw18em {
  min-width: 18em;
}

.minw19em {
  min-width: 19em;
}

.minw20em {
  min-width: 20em;
}

/* maxEM size */
.maxw1em {
  max-width: 1em;
}

.maxw2em {
  max-width: 2em;
}

.maxw3em {
  max-width: 3em;
}

.maxw4em {
  max-width: 4em;
}

.maxw5em {
  max-width: 5em;
}

.maxw6em {
  max-width: 6em;
}

.maxw7em {
  max-width: 7em;
}

.maxw8em {
  max-width: 8em;
}

.maxw9em {
  max-width: 9em;
}

.maxw10em {
  max-width: 10em;
}

.maxw11em {
  max-width: 11em;
}

.maxw12em {
  max-width: 12em;
}

.maxw13em {
  max-width: 13em;
}

.maxw14em {
  max-width: 14em;
}

.maxw15em {
  max-width: 15em;
}

.maxw16em {
  max-width: 16em;
}

.maxw17em {
  max-width: 17em;
}

.maxw18em {
  max-width: 18em;
}

.maxw19em {
  max-width: 19em;
}

.maxw20em {
  max-width: 20em;
}

/************************** ClearFIX**************************/
/* For modern browsers */
.cf:before, .cf:after {
  content: "";
  display: block;
}

.cf:after {
  clear: both;
}

/************************** ドロップシャドウ**************************/
.shadow {
  /* Firefox */
  -webkit-box-shadow: 5px 5px 15px #000;
  /* Safari and Chrome */
  box-shadow: 5px 5px 15px #000;
  /* Opera 10.5+, IE */
}

/************************** 透過ロールオーバー **************************/
a:hover img.hover {
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  opacity: 0.7;
}

/************************** 文字寄せ **************************/
.tac {
  text-align: center;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

/* レスポンシブ用 */
.r-tac {
  text-align: left;
}

.r-tal {
  text-align: left;
}

.r-tar {
  text-align: left;
}

@media screen and (min-width: 768px) {
  .r-tac {
    text-align: center;
  }

  .r-tal {
    text-align: left;
  }

  .r-tar {
    text-align: right;
  }
}
/************************** 中心揃え **************************/
.vam {
  vertical-align: middle;
}

.vat {
  vertical-align: top;
}

.vab {
  vertical-align: bottom;
}

.dis-ib {
  display: inline-block;
}

.dis-b {
  display: block;
}

.dis-i {
  display: inline;
}

/**************************文字サイズ設定**************************/
.txtsmall {
  font-size: 80% !important;
  line-height: 1.2em;
}

.txtbig {
  font-size: 114.3% !important;
  line-height: 1.6em;
}

.txtbigger {
  font-size: 142.9% !important;
  line-height: 1.6em;
}

.txtbiggest {
  font-size: 171.4% !important;
  line-height: 1.6em;
}

.txtbold {
  font-weight: bold;
}

.caption {
  font-size: 90% !important;
  line-height: 1.4em;
  font-weight: 400;
  display: block;
  text-align: center;
  margin-top: 5px;
}

/**************************アイコン設定**************************/
.iconuse a[target="_blank"]:after, .iconuse a[href$=".pdf"]:after, .iconuse a[href$=".doc"]:after, .iconuse a[href$=".docx"]:after, .iconuse a[href$=".xls"]:after, .iconuse a[href$=".xlsx"]:after, .iconuse a[href$=".zip"]:after, .iconuse a[href^="mailto:"]:after,
.iconuse-white a[href^="http"]:after, .iconuse-white a[href$=".pdf"]:after, .iconuse-white a[href$=".doc"]:after, .iconuse-white a[href$=".docx"]:after, .iconuse-white a[href$=".xls"]:after, .iconuse-white a[href$=".xlsx"]:after, .iconuse-white a[href$=".zip"]:after, .iconuse-white a[href^="mailto:"]:after {
  font-family: "FontAwesome";
  display: inline-block;
  color: #FF0C54;
  margin-left: 0.2em;
  /*text-rendering: auto;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none !important;
}

.iconuse-white a[target="_blank"]:after, .iconuse-white a[href$=".pdf"]:after, .iconuse-white a[href$=".doc"]:after, .iconuse-white a[href$=".docx"]:after, .iconuse-white a[href$=".xls"]:after, .iconuse-white a[href$=".xlsx"]:after, .iconuse-white a[href$=".zip"]:after, .iconuse-white a[href^="mailto:"]:after {
  color: #fff;
}

/* 外部リンク要素 --- */
.iconuse a[target="_blank"]:after, .iconuse-white a[target="_blank"]:after {
  content: "\f08e";
}

/* pdf要素--- */
.iconuse a[href$=".pdf"]:after, .iconuse-white a[href$=".pdf"]:after {
  content: "\f1c1";
}

/* Word要素--- */
.iconuse a[href$=".doc"]:after, .iconuse a[href$=".docx"]:after, .iconuse-white a[href$=".doc"]:after, .iconuse a[href$=".docx"]:after {
  content: "\f1c2";
}

/* Excel要素--- */
.iconuse a[href$=".xls"]:after, .iconuse a[href$=".xlsx"]:after, .iconuse-white a[href$=".xls"]:after, .iconuse a[href$=".xlsx"]:after {
  content: "\f1c3";
}

/* zip要素--- */
.iconuse a[href$=".zip"]:after, .iconuse-white a[href$=".zip"]:after {
  content: "\f1c6";
}

/* mailto要素--- */
.iconuse a[href^="mailto:"]:after, .iconuse-white a[href^="mailto:"]:after {
  content: "\f003";
}

.iconuse a.noicon {
  display: none;
}
