/* ===================================

    drawer

=================================== */
.drawer-nav {
  z-index: 3;
  @include mq-up {
    transform: none !important;
  }
}
.drawer-overlay {
  z-index: 2 !important;
}

.categoryNav {
  $parent: &;
  background-color: #222222;
  padding: 0 15px;
  height: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  @include mq-up {
    width: 100%;
    height: 50px;
  }
  @include mq-down {
    padding: 0;
  }
  &__nav {
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $contents_width;
    margin-right: auto;
    margin-left: auto;
    // padding-right: 15px;
    // padding-left: 15px;
    // @include mq-down {
    //   padding-right: 0;
    //   padding-left: 0;
    // }
  }
  & &__navWrap {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: visible;
    background-color: transparent;
    position: static;

    @include mq-down {
      background-color: #F4F4F4;
      width: 16.25rem;
      overflow: scroll;
      position: fixed;
      z-index: 3;
    }
  }
  &__navTop {
    display: flex;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #AAA;
    @include mq-up {
      display: none;
    }
  }
  &__navBtn {
    background-color: #333;
    max-width: 48%;
    flex: 1 0 480%;
    margin: 1%;
    text-align: center;
    color: #ffffff;
    padding: 5px 15px;
  }
  &__title {
    background-color: #222;
    display: none;
    color: #ffffff;
    padding: 15px;
    @include mq-down {
      display: block;
      width: 100%;
    }
  }
  &__navlist {
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: visible;

    @include mq-up {
      position: static;
      flex-direction: row;
      background: transparent;
    }

    @include mq-down {
      width: 16.25rem;
      background-color: #F4F4F4;
      justify-content: flex-start;
      z-index: 3;
    }

  }
  &__navitem {
    height: inherit;
    @include mq-down {
      width: 100%;
      height: auto;
      border-bottom: 1px solid #AAA;
    }
    &:hover {
      & #{$parent}__navlink {
        &::before {
          transform: translateY(0);
        }
      }
    }
  }
  &__navlink {
    height: inherit;
    display: flex;
    align-items: center;
    color: #ffffff;
    position: relative;
    overflow: hidden;
    @include mq-down {
      color: #333333;
      padding: 15px;
      justify-content: space-between;
    }
    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      left: 30%;
      bottom: 0;
      border-width: 0 10px 10px 10px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      z-index: 1;
      transform: translateY(100%);
      transition: transform 0.1s ease-in-out;
      @include mq-down {
        display: none;
      }
    }
    &::after {
      @include mq-down {
        content: '\f105';
        font-family: "FontAwesome";
      }
    }
    &--hasChild {
      &::after {
        content: '\f107';
        font-family: "FontAwesome";
        font-weight: 400;
        padding-left: 5px;
        @include mq-down {
          content: '\f067';
        }
      }
      &.open {
        &::after {
          content: '\f068';
        }
      }
    }
  }

  .serchForm {
    display: flex;
    @include mq-down {
      position: fixed;
      top: 50px;
      left: 0;
      width: 100%;
      height: 40px;
      background-color: #222;
      z-index: 2;
      padding: 5px 10px;
    }
    &__form {
      display: flex;
      width: 100%;
    }
    &__select {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      background-color: #F8F8F8;
      padding-left: 15px;
      position: relative;
      width: 165px;
      overflow: hidden;
      @include mq-down {
        width: 10em;
      }
      .category_id {
        background-color: transparent;
        max-width: 130px;
        height: 30px;
        @include mq-down {
          max-width: 4em;
        }
      }
      &::before {
        position: absolute;
        top: 1em;
        right: 0.4em;
        width: 0;
        height: 0;
        padding: 0;
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #222;
        pointer-events: none;
      }
    }
    &__input {
      -webkit-appearance: none;
      appearance: none;
      border: none;
      width: 310px;
      height: 30px;
      padding: 5px;
      @include mq-down {
        width: 100%;
      }
    }
    &__btn {
      background-color: #F9D023;
      padding: 0 15px;
      margin-left: -5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      cursor: pointer;
    }
  }
  .drawer-hamburger {
    width: 20px;
    height: 20px;
    padding: 15px;
    @include mq-up {
      display: none;
    }
    @include mq-up(lg) {
      display: none;
    }
    &-icon {
      margin-top: 0;
      &::before, &::after {
        background-color: #fff;
      }
      &::before {
        body:not(.drawer-open) & {
          top: -7px;
          background-color: #222;
        }
      }
      &::after {
        body:not(.drawer-open) & {
          top: 7px;
          background-color: #222;
        }
      }
    }
  }
}

.megamenu {
  position: absolute;
  left: 0;
  width: 100%;
  max-height: 0px;
  z-index: 1;
  overflow: hidden;
  // transition: all 0.25s ease-in-out;
  .categoryNav__navitem:hover & {
    max-height: 9999px;
    // transition: all 1s ease-in-out;
    padding-bottom: 10px;
    opacity: 1;
    @include mq-down {
      max-height: 0;
      padding-bottom: 0;
    }
  }
  @include mq-down {
    position: static;
    .categoryNav__navitem &.megamenu--open {
      max-height: 9999px;
    }
  }

  &__inner {
    width: 100%;
    height: 100%;
    max-width: $contents_width;
    margin-right: auto;
    margin-left: auto;
    padding: 30px 60px;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.25);
    @include mq-down {
      flex-direction: column;
      padding: 15px;
      box-shadow: none;
    }
    // &::after {
    //   content: '';
    //   display: block;
    //   width: 100%;
    //   box-shadow: 1px 1px 10px rgba(0,0,0,0.25);
    // }
  }
  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 80px;
    @include mq-down {
      min-height: 1px;
      flex-direction: row;
      justify-content: flex-start;
      img {
        width: 50px;
      }
    }
  }
  &__item {
    max-width: 16.66667%;
    flex: 1 0 16.66667%;
    text-align: center;
    margin-bottom: 15px;
    @include mq-down {
      max-width: 100%;
      flex: 1 0 auto;
    }
  }
  &__itemName {
    @include fontsize(12);
    font-weight: 700;
    padding: 5px;
    @include mq-down {
      text-align: left;
      br {
        display: none;
      }
    }
  }
  .catImage {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}